<template>
  <div class="color-picker-container">
    <div class="text-secondary">{{ text }}</div>
    <div class="color-rows">
      <div
        v-for="(color, index) in colors"
        :key="`color-${index}`"
        class="color-circle-container position-relative"
        :class="{
          selected: color === selectedColor,
          'color-circle-container-lastOfVisible':
            (index === visibleColors - 1 && !showAll) ||
            (index === colors.length - 1 && showAll),
        }"
        v-show="showAll || index < visibleColors"
        @click="selectColor(color)"
        @mouseenter="hovering = color"
        @mouseleave="hovering = null"
      >
        <div class="color-circle" :style="{ backgroundColor: color }"></div>
        <font-awesome-icon
          v-if="color === hovering || color === selectedColor"
          icon="check"
          class="color-circle-icon"
        />
      </div>
      <div v-if="colors.length > visibleColors" class="sign-container">
        <button
          aria-label="Mostrar colores"
          class="toggle-btn btn"
          @click="toggleShowAll"
        >
          <font-awesome-icon
            :icon="showAll ? 'minus' : 'plus'"
            class="show-all-icon"
          />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    colors: Array,
    selectedColor: String,
    text: {
      type: String,
      default: "Seleccione un color *",
    },
  },
  data() {
    return {
      showAll: false,
      visibleColors: 7,
      hovering: null,
    };
  },

  watch: {
    selectedColor(color) {
      if (color !== "") {
        if (
          !this.colors.slice(0, this.visibleColors).includes(this.selectedColor)
        ) {
          this.showAll = true;
        }
      }
    },
  },

  methods: {
    selectColor(color) {
      this.$emit("update:selectedColor", color);
    },
    toggleShowAll() {
      this.showAll = !this.showAll;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/abstracts/_variables.scss";

.color-picker-container {
  max-width: 368px;
  margin: auto;
}

.color-rows {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  right: 2px;
}

.color-circle-container,
.sign-container {
  cursor: pointer;
  width: 30px;
  height: 30px;
  padding: 8px;
  flex: 0 0 auto;
}
.color-circle-container {
  background-color: $background-color-unset;
}

.color-circle-container:first-of-type {
  border-radius: 0.5em 0 0 0;
}
.color-circle-container-lastOfVisible {
  border-radius: 0 0 0.5em 0;
}

.color-circle {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.color-circle-container.toggle-btn-container {
  background-color: transparent;
}

.toggle-btn {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  padding: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #e6e6e6;
  transform: translate(-4%, -6%);
  background-color: transparent;
}
.show-all-icon {
  font-size: 0.6em;
  color: $blue-color;
}

.color-circle-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.5em;
  color: white;
  display: none;
}

.color-circle-container:hover .color-circle-icon,
.color-circle-container.selected .color-circle-icon {
  display: block;
}
</style>
